class UpcomingEvents {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;

		this.paged = 1;
		this.max = 1;

		this.postContainer = this.block.querySelector('.event-card-container');

		this.currentPageEl = this.block.querySelector('.current-page');
		this.maxPagesEl = this.block.querySelector('.max-pages');

		this.allFilters = this.block.querySelectorAll('input[type="radio"]');
		this.taxFilters = this.block.querySelectorAll('input[type="radio"].tax_query');
		this.metaFilters = this.block.querySelectorAll('input[type="radio"].meta_query');

		this.applyFiltersBtn = this.block.querySelector('.btn-apply');
		this.resetFiltersBtn = this.block.querySelector('.btn-reset');

		this.nextPageBtn = this.block.querySelector('.btn-next');
		this.prevPageBtn = this.block.querySelector('.btn-previous');

		this.showFiltersBtn = this.block.querySelector('.btn-show-filters');
		this.hideFiltersBtn = this.block.querySelector('.btn-hide-filters');
		this.filtersPanel = this.block.querySelector('.search-filters__sticky');

		this.init();
	}

	init() {
		this.allFilters.forEach( filter => {
			filter.addEventListener('change', () => {
				this.applyFiltersBtn.removeAttribute('disabled');
			});
		});

		if ( this.applyFiltersBtn ) {
			this.applyFiltersBtn.addEventListener('click', () => {
				this.search(true);
			});
		}

		if ( this.resetFiltersBtn ) {
			this.resetFiltersBtn.addEventListener('click', () => {
				this.allFilters.forEach( filter => {
					if ( filter.value === 'all' ) {
						filter.checked = true;
					} else {
						filter.checked = false;
					}
				});

				this.search(true, true);
			});
		}

		if ( this.nextPageBtn ) {
			this.nextPageBtn.addEventListener('click', () => {
				const page = parseInt(this.nextPageBtn.dataset.page);
				this.max = parseInt(this.nextPageBtn.dataset.max);

				this.paged = page;

				this.handlePagination();
				this.search();
			});
		}

		if ( this.prevPageBtn ) {
			this.prevPageBtn.addEventListener('click', () => {
				const page = this.prevPageBtn.dataset.page;
				this.paged = page;

				this.handlePagination();
				this.search();
			});
		}

		if ( this.showFiltersBtn ) {
			this.showFiltersBtn.addEventListener('click', () => {
				this.filtersPanel.classList.add('show');
			});
		}

		if ( this.hideFiltersBtn ) {
			this.hideFiltersBtn.addEventListener('click', () => {
				this.filtersPanel.classList.remove('show');
			});
		}
	}

	/**
	 * Handle pagination
	 */
	handlePagination() {
		this.currentPageEl.innerHTML = this.paged;

		if ( this.paged > 1 ) {
			this.prevPageBtn.setAttribute('data-page', parseInt(this.paged) - 1);
			this.prevPageBtn.removeAttribute('disabled');
		} else {
			this.prevPageBtn.setAttribute('disabled', true);
		}

		if ( this.paged === this.max ) {
			this.nextPageBtn.setAttribute('disabled', true);
		} else {
			this.nextPageBtn.setAttribute('data-page', parseInt(this.paged) + 1);
			this.nextPageBtn.removeAttribute('disabled');
		}
	}

	/**
	 * Search for posts
	 */
	search(isFilter = false, isReset = false) {
		this.postContainer.classList.add('loading');

		if ( isFilter ) {
			this.resetFiltersBtn.removeAttribute('disabled');

			this.paged = 1;
			this.handlePagination();
		}

		if ( isReset ) {
			this.applyFiltersBtn.setAttribute('disabled', true);
			this.resetFiltersBtn.setAttribute('disabled', true);
		}

		const appliedTaxFilters = [];
		this.taxFilters.forEach( filter => {
			if ( filter.checked ) {
				let data = {
					name: filter.name,
					value: filter.value,
				}

				appliedTaxFilters.push(data);
			}
		});

		const postData = {
			page: this.paged,
			taxFilters: appliedTaxFilters,
		}

		fetch(`${window.location.origin}/wp-json/events/v1/search`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(postData),
		})
			.then( response => response.json() )
			.then( data => {
				// console.log(data); // Debugging

				if ( data.posts ) {
					this.postContainer.innerHTML = data.posts;

					this.maxPagesEl.innerHTML = data.maxPages;
					this.max = parseInt(data.maxPages);
					this.handlePagination();

					this.postContainer.classList.remove('loading');
				} else {
					this.postContainer.innerHTML = '<p>Sorry, no events where found.</p>';
					this.postContainer.classList.remove('loading');
				}
			}).catch((err) => {
				console.error(` Err: ${err}`);
				this.postContainer.classList.remove('loading');
			});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-upcoming-events').forEach( block => new UpcomingEvents(block) );
});
